import React from 'react';
import Case from '../components/case'


const CaseEisenbahn = (props) => (
    <Case 
        seoTitle="Forle Marketing Case Eisenbahn"
        images={["1.jpg", "2.jpg", "3.jpg", "4.jpg"]} 
    >  
          <h1>Case Eisenbahn Oktoberfest</h1>
          <div className="row justify-content-center my-5">
              <div className="col-sm-12 col-lg-6">
                    <p>Uma <strong>experi&ecirc;ncia &uacute;nica</strong> que destacou tudo que a <strong>Oktoberfest</strong> pode oferecer de melhor. Pensando nos <strong>vendedores</strong> que fazem o festival acontecer, a Forle trouxe <strong>banda alem&atilde;</strong> para dar in&iacute;cio ao m&ecirc;s, e tamb&eacute;m prop&ocirc;s o <strong>Beer Sommelier Experience</strong>,</p>
                </div>
                <div className="col-sm-12 col-lg-6">
                        <p> espa&ccedil;o em que os vendedores poderiam provar que sabem sobre cerveja, adivinhando os itens que dentro do r&oacute;tulo de <strong>Eisenbahn</strong>, al&eacute;m de responder a perguntas semanais sobre a cerveja <strong>Eisenbahn Oktoberfest</strong>, e no final, serem premiados por isso. Essa intera&ccedil;&atilde;o trouxe <strong>grande participa&ccedil;&atilde;o, e valoriza&ccedil;&atilde;o do festival</strong>.&nbsp;</p>
                </div>
            </div>
    </Case>
)

export default CaseEisenbahn