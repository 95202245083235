import React from 'react';
import {Link} from 'gatsby'
import Layout from './layout';
import SEO from './seo';
import GatsbyImg from './gatsbyimg';


const Case = (props) => 
<Layout>
    
        <SEO title={props.seoTitle} />
        <section id="Case">
            <CaseHeader>
                    {props.children}
            </CaseHeader>
            <CaseImages images={props.images} />
            <CallToAction />
        </section>
</Layout>
export default Case

const CaseHeader = ({children}) => (
    <div className="container-fluid caseheader">
        <div className="container">
            {children}
        </div>
    </div>
)


const CaseImages = (props) => (
    <div className="container">
        <div className="row">
            {props.images.map( (imageName, key) => 
                <div className="col-md-6 col-sm-12 mb-4"  key={key}>
                    <GatsbyImg name={imageName}/>
                </div>
            )}
        </div>    
    </div>
)

const CallToAction =()=>{
return(
<div className="my-5">
    <h3 className="text-light text-center">Nossos Cases Principais</h3>
    <div className="cases-menu">
        <Link to="/casedanone" className="btn btn-primary m-2">Case Danone</Link>
        <Link to="/casedevassa" className="btn btn-primary m-2">Case Devassa</Link>
        <Link to="/caseeisenbahn" className="btn btn-primary m-2">Case Eisenbahn</Link>
        <Link to="/caseraizen" className="btn btn-primary m-2">Case Raizen</Link>
    </div>
</div>
)}
